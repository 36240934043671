<template>
  <div>
    <table-server-side-v-1-widget
      :url="url"
      :columns="cColumns"
      :actions="cActions"
      request-columns-filter-type="base64"
      @clickView="tableClickView"
    />
  </div>
</template>

<script>
export default {
  props: {
    keyName: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      statusSettingInfoText: {
        'Wait Document': 'รอเอกสารยืนยันตัวตน',
        Submit: 'รออนุมัติ',
        Approval: 'อนุมัติ',
        Reject: 'ไม่อนุมัติ',
      },
      statusAgreementInfo: {
        Pending: 'รอเอกสารยืนยันตัวตนอนุมัติ',
        Approval: 'อนุมัติ',
        Reject: 'ไม่อนุมัติ',
        'Create document contract': 'รอครีเอเตอร์เซ็นเอกสารสัญญา',
        'Pending document contract': 'รอแอดมินอนุมัติเอกสารสัญญา',
      },
    }
  },
  computed: {
    cColumns() {
      const commonColumns = [
        {
          label: 'วันที่สมัคร (วัน-เดือน-ปี)',
          field: 'register_date',
          formatFn: value => this.$date(value).format('DD-MM-YYYY HH:mm'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'อีเมล / เบอร์โทรศัพท์',
          field: 'email',
        },
        {
          label: 'ชื่อร้าน',
          field: 'shop_name',
        },
        {
          label: 'ชื่อ นามสกุล',
          field: 'full_name',
        },
      ]

      if (this.keyName === 'all') {
        return [
          ...commonColumns,
          {
            label: 'ยอดขาย',
            field: 'sale_total_amount',
            type: 'number',
          },
          {
            label: 'ยอดขายสุทธิ (หักส่วนต่างแล้ว)',
            field: 'sale_total_withholding',
            type: 'number',
          },
          {
            label: 'สถานะบัญชี',
            field: rowObj => {
              const { verify_setting_info, verify_agreement_info } = rowObj
              if (verify_setting_info.status === 'Approval' && verify_agreement_info.status === 'Approval') {
                return 'อนุมัติ'
              }

              if (verify_setting_info.status === 'Reject' || verify_agreement_info.status === 'Reject') {
                return 'ไม่อนุมัติ'
              }

              return 'รออนุมัติ'
            },
            type: 'status',
            thClass: 'text-center',
            tdClass: 'text-center',
            sortable: false,
            filterable: false,
            filterOptions: {
              enable: false,
            },
            renderVariant: ({ row }) => {
              // console.log('renderVariant', row)
              const { verify_setting_info, verify_agreement_info } = row

              if (verify_setting_info.status === 'Approval' && verify_agreement_info.status === 'Approval') {
                return 'success'
              }

              if (verify_setting_info.status === 'Reject' || verify_agreement_info.status === 'Reject') {
                return 'danger'
              }

              return 'secondary'
            },
          },
        ]
      }

      return [
        ...commonColumns,
        {
          label: 'เอกสารยืนยันตัวตน',
          field: rowObj => this.statusSettingInfoText[rowObj.verify_setting_info.status],
          type: 'status',
          renderVariant: ({ row }) => {
            const { status } = row.verify_setting_info
            if (status === 'Approval') return 'success'
            if (status === 'Reject') return 'danger'

            return 'secondary'
          },
          tdClass: 'text-center',
          thClass: 'text-center',
          filterable: false,
          filterOptions: {
            enable: false,
          },
        },
        {
          label: 'เอกสารสัญญา',
          field: rowObj => this.statusAgreementInfo[rowObj.verify_agreement_info.status],
          type: 'status',
          renderVariant: ({ row }) => {
            const { status } = row.verify_agreement_info
            if (status === 'Approval') return 'success'
            if (status === 'Reject') return 'danger'

            return 'secondary'
          },
          tdClass: 'text-center',
          thClass: 'text-center',
          filterable: false,
          filterOptions: {
            enable: false,
          },
        },
      ]
    },
    cActions() {
      return [{ keyEmit: 'clickView', label: 'ดูข้อมูลเพิ่มเติม', iconName: 'EyeIcon' }]
    },
  },
  methods: {
    tableClickView(rowObj) {
      // console.log('tableClickView', rowObj)
      // partners-management-details
      const { user_id } = rowObj
      if (this.keyName === 'all') {
        this.$router.push({ name: 'partners-management-details', params: { id: user_id }, query: { tab: 'general' } })
      } else {
        this.$router.push({ name: 'partners-management-details', params: { id: user_id }, query: { tab: 'account' } })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
